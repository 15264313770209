<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>

    <div class="content">
      <div class="content-left">
        <div class="qrcode-container">
          <vue-qrcode
            tag="img"
            :value="`${WX_URL}/answer?ansId=${answerId}&userId=${userId}`"
            class="qrcode"
          />
        </div>
        <div class="content-left-button">
          <el-button @click="getData">
            <img class="reload" src="@/assets/images/interactive/reload.png" alt="" />
            <!-- 刷新 -->
            <p class="reloadp">刷新</p>
          </el-button>
          <el-button v-if="statistic.examStatus == 2" @click="answerEnd">
            <img class="read" src="@/assets/images/interactive/read.png" alt="" />
            <p class="readp">结束答题</p>
          </el-button>
        </div>
        <div class="content-left-exam-info">
          <div class="content-left-column">
            <p style="text-align: right">成员人数：</p>
            <p>答题中人数：</p>
            <p>已交卷人数：</p>
          </div>

          <div class="content-left-column">
            <p>{{ statistic.totalNum }}</p>
            <p>{{ statistic.beBeingNum }}</p>
            <p>{{ statistic.achieveNum }}</p>
          </div>
        </div>
      </div>

      <div class="content-right">
        <div class="status">
          <div class="status-color-block-red"></div>
          <span> 已交卷</span>
          <div class="status-color-block-green"></div>
          <span>答题中 </span>
        </div>
        <div class="answer-list">
          <div
            class="answer-img"
            v-for="item in answerers"
            :key="item.id"
            :style="isEnd ? 'margin-left: 60px;' : 'margin-left: 80px;'"
          >
            <img :src="item.wxAvatarUrl" alt="头像" />
            <div class="answer-info">
              <div>{{ item.wxName }}</div>
              <div
                class="status-color-block-green"
                v-if="item.examType == 0"
              ></div>
              <div class="status-color-block-red" v-else></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isEnd" class="content-ranking">
        <div v-if="leaderboard.length <= 3">
          <div class="top-three-after-row">
            <div style="color: #b40000">排名</div>
            <div>头像</div>
            <div>名称</div>
            <div>耗时</div>
            <div style="color: #67c23a">答对题数</div>
          </div>
          <div
            v-for="(item, index) in leaderboard"
            :key="item.id"
            class="top-three-after-row"
          >
            <div style="color: #b40000">{{ index + 1 }}</div>
            <img class :src="item.wxAvatarUrl" alt="" />
            <div>{{ item.wxName }}</div>
            <div>{{ item.elapsedTime }}分钟</div>
            <div style="color: #67c23a">{{ item.correctNum }}</div>
          </div>
        </div>

        <div v-else class="top">
          <div class="top-two">
            <img class="top-two-avatar" :src="topTwo.wxAvatarUrl" alt="" />
            <img
              class="top-two-silver-medal"
              src="@/assets/images/interactive/top-2.png"
              alt=""
            />

            <p class="name">{{ topTwo.wxName }}</p>
            <div>{{ topTwo.elapsedTime }}分钟/{{ topTwo.correctNum }}</div>
          </div>
          <div class="top-one">
            <img class="top-one-avatar" :src="topOne.wxAvatarUrl" alt="" />
            <img
              class="top-one-gold-medal"
              src="@/assets/images/interactive/top-1.png"
              alt=""
            />
            <p class="name">{{ topOne.wxName }}</p>
            <div>{{ topTwo.elapsedTime }}分钟/{{ topOne.correctNum }}</div>
          </div>
          <div class="top-three">
            <img class="top-three-avatar" :src="topThree.wxAvatarUrl" alt="" />
            <img
              class="top-three-bronze-medal"
              src="@/assets/images/interactive/top-3.png"
              alt=""
            />
            <p class="name">{{ topThree.wxName }}</p>
            <div>{{ topTwo.elapsedTime }}分钟/{{ topThree.correctNum }}</div>
          </div>
        </div>

        <div v-if="leaderboard.length > 3" class="top-three-after">
          <div class="top-three-after-row">
            <div style="color: #b40000">排名</div>
            <div>头像</div>
            <div>名称</div>
            <div>耗时</div>
            <div
              :style="
                useSubject === 'red' ? 'color: #b40000' : 'color: #1e37ca'
              "
            >
              答对题数
            </div>
          </div>
          <div
            v-for="(item, index) in leaderboard.slice(3)"
            :key="item.id"
            class="top-three-after-row"
          >
            <div
              :style="
                useSubject === 'red' ? 'color: #b40000' : 'color: #1e37ca'
              "
            >
              {{ index + 4 }}
            </div>
            <img class :src="item.wxAvatarUrl" alt="" />
            <div>{{ item.wxName }}</div>
            <div>{{ item.elapsedTime }}分钟</div>
            <div style="color: #67c23a">{{ item.correctNum }}</div>
          </div>
        </div>
      </div>
    </div>
    <back :pageDepth="3"></back>
    <menu-pop :activeItem="2"></menu-pop>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import back from '@/components/back'
import navRouter from '@/components/navRouter'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters } from 'vuex'
import vueQrcode from '@chenfengyuan/vue-qrcode'
import { API_MODULE } from '@/request/api'

export default {
  name: 'Answer',
  components: {
    menuPop,
    navRouter,
    vueQrcode,
    back
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundColor: '#E03838'
      },
      bgRed: {
        backgroundColor: '#E03838'
      },
      bgBlue: {
        backgroundColor: '#2070DB'
      },
      isEnd: false,
      answerId: undefined,
      userId: undefined,
      // 统计
      statistic: {},
      answerers: [],
      leaderboard: [],
      topOne: {},
      topTwo: {},
      topThree: {}
    }
  },
  methods: {
    getData() {
      this.GET(API_MODULE.GET_EXAM_STATUS_DATA, {
        id: this.answerId
      }).then((res) => {
        this.statistic = res.data.statisticsUserExam || {}
        this.answerers = res.data.userList
        this.leaderboard = res.data.ranking
        this.topOne = res.data.ranking.slice(0, 1)[0]
        this.topTwo = res.data.ranking.slice(1, 2)[0]
        this.topThree = res.data.ranking.slice(2, 3)[0]
        this.leaderboard.length !== 0 ? (this.isEnd = true) : (this.isEnd = false)
      })
    },

    answerEnd() {
      this.POST(API_MODULE.ANSWER_END, { id: this.answerId })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.getData()
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message(err.msg)
        })
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }
    this.answerId = this.$route.query.id

    const user = JSON.parse(window.localStorage.getItem('USER'))
    this.userId = user.id
    this.getData()
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    to.meta.keepAlive = true
    next()
  },
}
</script>

<style lang="scss" scoped>
@import "@/themes/_handle.scss";

.box {
  min-height: 13.6rem;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    display: flex;
    height: 11.8rem;
    margin-top: 0.6rem;

    .content-left {
      flex: 1;
      margin-left: 0.6rem;

      .qrcode-container {
        width: 7rem;
        height: 4.8rem;
        background-color: #ffffff;
        @include border_color("border-light");
        border-style: solid;
        border-width: 0.1rem;

        .qrcode {
          width: 4.22rem;
          height: 4.22rem;
          margin-top: 0.2rem;
        }
      }

      .content-left-button {
        margin-top: 0.4rem;
        display: flex;
        justify-content: space-between;
        button { 
          .reload{
            margin-top: -0.1rem;
            height: 0.5rem;
            width: 0.5rem;
          }
           .reloadp{
            margin-top: 0.04rem;
            float:right;
            font-style: 16px;
            margin-right: 0.4rem;
            }
          .read{
            float: left;
            margin-top: -0.1rem;
            height: 0.5rem;
            width: 0.5rem;
            }
          .readp{
            margin-top: -0.02rem;
            float:right;
            font-style: 16px;
            // margin-right: 0.4rem;
            margin-left: 0.2rem;
            }
          width: 3.2rem;
          height: 1rem;
          // line-height: 1rem;
          background: #cca766;
          opacity: 1;
          border-radius: 0.05rem;
          font-size: 0.31rem;
          color: #ffffff;
          text-align: center;
          border: 1px solid #cca766;
        }
      }

      .content-left-exam-info {
        width: 7rem;
        height: 4.8rem;
        background-color: #ffffff;
        @include border_color("border-light");
        border-style: solid;
        border-width: 0.1rem;

        margin-top: 0.4rem;

        display: flex;
        justify-content: space-around;

        p {
          @include font_color("font-medium");
          font-size: 0.36rem;
          text-align: left;
          margin-top: 0.9rem;
        }
      }
    }

    .content-right {
      margin: 0 0.6rem;
      flex: 4;
      background-color: #ffffff;
      @include border_color("border-light");
      border-style: solid;
      border-width: 0.1rem;
      overflow: auto;
      .status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0.36rem;
        margin-top: 0.6rem;
        margin-right: 1.2rem;
        span {
          margin-left: 0.18rem;
          margin-right: 0.4rem;
          @include font_color("font-medium");
        }
      }

      .answer-list {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;

        .answer-img {
          margin-bottom: 0.8rem;
          img {
            height: 1.8rem;
            width: 1.8rem;
            border-radius: 0.1rem;
          }
        }

        .answer-info {
          display: flex;
          justify-content: space-between;
          color: #555555;
          font-size: 0.26rem;
        }
      }
    }

    .content-ranking {
      margin-right: 0.6rem;
      flex: 4;
      background-color: #ffffff;
      @include border_color("border-light");
      border-style: solid;
      border-width: 0.1rem;
      overflow: auto;
      .top {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 1.12rem;
        .name {
          color: #000000;
          font-size: 0.3rem;
        }

        .score {
          color: #b40000;
          font-size: 0.24rem;
        }

        &-two {
          position: relative;
          width: 3rem;
          height: 3rem;
          &-avatar {
            position: absolute;
            width: 1.1rem;
            height: 1.1rem;
            border-radius: 100%;
            top: 0.96rem;
            left: 0.4rem;
          }

          &-silver-medal {
            margin-top: 0.96rem;
            width: 2.4rem;
            height: 2.4rem;
          }
        }
        &-one {
          position: relative;
          width: 3rem;
          height: 3rem;
          &-avatar {
            position: absolute;
            width: 1.46rem;
            height: 1.46rem;
            border-radius: 100%;
            top: 0.63rem;
            left: 0.38rem;
          }
          &-gold-medal {
            margin-top: 0.69rem;
            width: 2.9rem;
            height: 2.9rem;
          }
        }
        &-three {
          position: relative;
          width: 3rem;
          height: 3rem;
          &-avatar {
            position: absolute;
            width: 1.1rem;
            height: 1.1rem;
            border-radius: 100%;
            top: 1.09rem;
            left: 0.3rem;
          }
          &-bronze-medal {
            margin-top: 1.12rem;
            width: 2.3rem;
            height: 2.3rem;
          }
        }
      }

      .top-three-after {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        font-size: 0.28rem;
        color: #555555;

        &-row {
          display: flex;
          align-items: center;
          justify-content: space-around;

          & > img {
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 100%;
          }
        }
      }
    }
  }

  .status-color-block-red {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    // background-color: #b40000;
    @include background_color("background-medium");
  }

  .status-color-block-green {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    background-color: #16a510 !important;
  }
}
.top-three-after-row {
  margin-top: 1.1rem !important;
}

.top-three-after-row > * {
  flex: 1;
}

.top-two-avatar,
.top-three-avatar,
.top-one-avatar {
  position: absolute;
  top: 60% !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(-50%);
}
</style>